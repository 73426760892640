<template>
  <div>
    <!-- <p class="p-text-title">Transacciones</p> -->
    <div class="content-transation">
      <skeleton-searchbar-transation-component />
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.content-transation {
  display: block;
  width: 100%;
}

.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.content-information {
  align-items: center;
  display: flex;
}

.p-title {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
}
.p-text {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: "pop-Regular";
  font-size: 14px;
}
.text-color {
  /* -webkit-text-stroke: .5px black; */
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  width: 50%;
  border: solid var(--primary-color-text) 1px !important;
  /* margin-left: 10px; */
  font-family: "pop-Regular" !important;
  font-size: 14px !important;
}
.content-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  height: 100px;
  width: 100px;
}
.content-img img {
  width: 100%;
  height: auto;
}
@supports (object-fit: cover) {
  .content-img img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}
.content-btn {
  width: 170px;
  align-self: center;
}
.text-transation-type {
  font-family: "pop-Bold";
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information {
    display: block;
    width: 100%;
  }
  .centered-image {
    display: flex;
    justify-content: center !important;
    text-align-last: center !important;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .content-information {
    margin-bottom: 10px;
  }
  .p-text-title {
    text-align: center;
  }
  .p-title {
    text-align: center;
  }

  .p-text {
    text-align: center;
    margin-left: 0px;
  }
  .content-color {
    text-align: -webkit-center;
  }
  .content-btn {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>